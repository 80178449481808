module.exports = [{
      plugin: require('C:/Users/msim/code/bangortransitstudy/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/msim/code/bangortransitstudy/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/msim/code/bangortransitstudy/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-70065106-25","cookieDomain":"bangortransit.study"},
    },{
      plugin: require('C:/Users/msim/code/bangortransitstudy/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
