import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
// import Overlay from '../components/Overlay'

export default ({ children, pageContext }) => {
  return (
    <React.Fragment>
      <Helmet
        script={[
          {
            src:
              'https://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.3/modernizr.min.js',
            type: 'text/javascript',
          },
        ]}
      >
        <meta
          name="description"
          content="This study will develop strategic plans to improve transit for the Greater Bangor Urbanized Area."
        />
        <meta
          name="keywords"
          content="Bangor,Maine,community,connector,bus,transit,study,survey,bat"
        />
        <meta name="theme-color" content="#8A140B" />
      </Helmet>
      {children}
      {/* <Overlay /> */}
    </React.Fragment>
  )
}
